<template>
  <tr>
    <td class="text-center x-sm">
      <img
        v-if="!product.path_img_thumb"
        :src="require('@/assets/images/supply-item.png')"
        alt=""
        class="image-placeholder"
        :class="{ sm: !isLarge }"
      />
      <v-img
        v-else
        :src="product.path_img_thumb"
        @click="showImgDialog(product)"
        class="image"
        v-show="isImgLoaded"
        @load="showImg"
        :class="{ sm: !isLarge }"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              :size="isLarge ? '20' : '10'"
              :width="isLarge ? '2' : '1'"
              color="green lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </td>
    <td class="text-left">{{ product.name }}</td>
    <td
      class="text-left"
      @click="
        priceInitEdit = true;
        $nextTick(() => $refs.editInit.focus());
      "
      width="60%"
    >
      <v-row no-gutters>
        <div class="w-100" v-if="!priceInitEdit">
          <v-text-field
            v-model="product.price_init"
            dense
            disabled
            color="success"
            :placeholder="$t('form.not_specified')"
            hide-details
            class="price-input"
          ></v-text-field>
        </div>
        <div v-else class="w-100">
          <v-text-field
            ref="editInit"
            autofocus
            v-model="product.price_init"
            v-click-outside="savePrice"
            dense
            color="success"
            @keypress="onKeyChangePrice($event, product)"
            min="0"
            clearable
            clear-icon="mdi-delete-circle"
            hide-details
            style="width: 100%"
            height="100%"
          ></v-text-field>
        </div>
      </v-row>
    </td>
    <!-- <td width="20%" class="text-left">
      {{ product.price_min || $t("form.not_specified") }}
    </td>
    <td width="20%" class="text-left">
      {{ product.price_max || $t("form.not_specified") }}
    </td> -->
    <td class="text-center icon">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="
              !product.price_init || !product.price_min || !product.price_max
            "
            @click="confirmClear = true"
            icon
            v-on="on"
            v-bind="attrs"
            small
            color="error"
            ><v-icon small color="error">mdi-close</v-icon></v-btn
          >
        </template>
        {{ $t("table.reset_prices") }}
      </v-tooltip>
    </td>
    <imgDialog
      :visible="imgDialog"
      @close="imgDialog = false"
      :imgpath="imgpath"
    />
    <confirm-dialog
      :visible="confirmClear"
      @close="confirmClear = false"
      @onConfirm="resetPrices"
      @onCancel="confirmClear = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>{{
        $t("confirmDialog.reset_prices.title")
      }}</template>
      <template v-slot:description>
        {{ $t("confirmDialog.reset_prices.desc") }}
      </template></confirm-dialog
    >
  </tr>
</template>

<script>
import _ from "lodash";
import SizeUi from "@/mixins/SizeUi";
import priceService from "@/services/request/prices/priceService";
import notifications from "@/mixins/notifications";
import EventBus from "@/events/EventBus";
import imgDialog from "../products/imgDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";

export default {
  mixins: [SizeUi, notifications],
  components: {
    imgDialog,
    ConfirmDialog,
  },
  data: () => ({
    isImgLoaded: false,
    copyProduct: {},
    priceInitEdit: false,
    imgDialog: false,
    imgpath: [],
    confirmClear: false,
  }),
  props: {
    product: {
      require: true,
    },
  },
  mounted() {
    this.copyProduct = { ...this.product };
  },
  methods: {
    showImgDialog(product) {
      this.imgpath = product.path_img;
      this.imgDialog = true;
    },
    onKeyChangePrice($event, product) {
      const newNumber = product.price_init + $event.key;
      if (newNumber.split(".").length - 1 > 1 && $event.keyCode == 46) {
        $event.preventDefault();
      }
      if ($event.keyCode === 13) {
        this.savePrice();
      }

      this.$checkNumberFloat(product.price_init, $event);
    },

    async savePrice() {
      if (this.product.price_init != this.copyProduct.price_init) {
        if (!this.product.price_init) {
          this.product.price_init = 0;
        }
        try {
          const formData = new FormData();
          formData.append("uuid_product", this.product.uuid_product);
          formData.append("price_init", this.product.price_init);
          await priceService.setPrice(formData).then((res) => {
            this.priceInitEdit = false;
            this.setSuccessNotification(this.$t('profile.successfully_updated'));
            this.copyProduct = { ...res };
            EventBus.$emit("prices-modified");
          });
        } catch (e) {
          this.setErrorNotification(e);
        }
      } else {
        this.priceInitEdit = false;
      }
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    async resetPrices() {
      try {
        await priceService.resetPrice(this.product.uuid_product);
        this.setSuccessNotification(this.$t('profile.successfully_updated'));
        this.confirmClear = false;
        EventBus.$emit("prices-modified");
      } catch (e) {
        this.confirmClear = false;
        this.setErrorNotification(e);
      }
    },
  },
  computed: {
    isChanged() {
      return !_.isEqual(this.product, this.copyProduct);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-input {
  width: 100%;
  font-size: 12px;
  cursor: pointer;
  min-width: 100px;
}
</style>