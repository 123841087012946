<template>
  <div>
    <v-card class="px-2 py-2 mb-2">
      <!-- <v-row class="align-center" no-gutters>
        <v-col cols="auto">
          <v-text-field
            v-model="searchString"
            @input="debounceInput"
            hide-details
            prepend-inner-icon="mdi-magnify"
            dense
            loader-height="2"
            :loading="searchLoading"
            clearable
            solo
            :placeholder="$t('form.search')"
          />
        </v-col>
      </v-row>
      <VDivider class="mt-3" /> -->
      <v-simple-table class="my-table" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center x-sm">
                <VIcon dense>mdi-tooltip-image</VIcon>
              </th>
              <th class="text-left font-weight-bold">{{ $t("table.name") }}</th>
              <th class="text-left font-weight-bold">
                {{ $t("table.init_price") }}
              </th>
              <!-- <th class="text-left font-weight-bold">{{ $t("table.min_price") }}</th>
              <th class="text-left font-weight-bold">{{ $t("table.max_price") }}</th> -->
              <th class="icon"></th>
            </tr>
          </thead>
          <tbody name="fade" is="transition-group">
            <prices-row
              v-for="(product, index) in products"
              :key="index"
              :product="product"
            />
          </tbody>
        </template>
      </v-simple-table>
      <v-progress-linear
        v-if="isLoadingFilter"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div
        v-observer="{ nextPage: nextPage }"
        class="observer"
        v-if="!loadMoreDisabled && !isLoadingFilter"
      ></div>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import priceService from "@/services/request/prices/priceService";
import loader from "@/mixins/loader";
import notifications from "@/mixins/notifications";
import language from "@/mixins/language";
import user from "../../mixins/user";
import paginationMixin from "../../mixins/paginationMixin";
import SizeUi from "../../mixins/SizeUi";
import pricesRow from "./pricesRow.vue";
import EventBus from "@/events/EventBus";

export default {
  components: {
    pricesRow,
  },
  mixins: [loader, notifications, language, user, paginationMixin, SizeUi],
  data: () => ({
    page: 1,
    products: [],
    isImgLoaded: false,
    searchLoading: false,
    searchString: "",
    loadMoreDisabled: false,
    isLoadingFilter: false,
  }),
  async mounted() {
    EventBus.$on("prices-modified", async () => {
      this.page = 1;
      await this.getProducts();
    });
    this.setLoading(true);
    await this.getProducts();
  },
  methods: {
    debounceInput: _.debounce(function () {
      this.onSearchProducts();
    }, 500),
    async nextPage() {
      this.isLoadingFilter = true;
      this.page += 1;
      await this.getProducts({}, true);
    },
    async onSearchProducts() {
      this.searchLoading = true;
      await this.getProducts(
        {
          name: this.searchString,
          uselike: true,
          offset: 0,
        },
      );
      this.searchLoading = false;
    },
    async getProducts(params = {}, next = false) {
      try {
        this.isLoadingFilter = true;
        const products = await priceService.getPrices({
          groupby: "uuid_product",
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...params,
        });
        if (!next) {
          this.products = products;
        } else {
          this.products.push(...products);
        }

        this.loadMoreDisabled = products.length < this.perPage;
        this.setLoading(false);
        this.isLoadingFilter = false;
      } catch (e) {
        this.setErrorNotification(e);
        this.setLoading(false);
      }
    },
  },
  watch: {
    page: {
      handler(value) {
        console.log(value);
      },
    },
  },
};
</script>

<style>
</style>